<template>
  <div
    class="h-screen text-a-neutral font-sans tracking-wide"
  >
    <DashboardNav class="hidden md:flex"/>
    <DashboardNavMobile class="flex md:hidden"/>
    <div
      class="flex flex-row w-full h-full"
    >
        <DashboardSideMenu class="hidden md:flex flex-none md:w-2/12 pt-8 border-r-2"/>
        <DashboardSideMenuMobile class="flex md:hidden"/>
      <div
        class="flex flex-col w-full md:w-10/12 pt-8"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style></style>
